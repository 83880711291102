<template>
  <div>
    <div class="loader loader--style2" title="Lütfen Bekleyiniz...">
      <svg width="100" height="100" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" stroke="#17a2b8">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" stroke-width="2">
            <circle cx="5" cy="50" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       values="50;5;50;50"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       begin="0s" dur="2.2s"
                       values="5;27;49;5"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
            <circle cx="27" cy="5" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       from="5" to="5"
                       values="5;50;50;5"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       begin="0s" dur="2.2s"
                       from="27" to="27"
                       values="27;49;5;27"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
            <circle cx="49" cy="50" r="5">
              <animate attributeName="cy"
                       begin="0s" dur="2.2s"
                       values="50;50;5;50"
                       calcMode="linear"
                       repeatCount="indefinite" />
              <animate attributeName="cx"
                       from="49" to="49"
                       begin="0s" dur="2.2s"
                       values="49;5;27;49"
                       calcMode="linear"
                       repeatCount="indefinite" />
            </circle>
          </g>
        </g>
      </svg>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 offset-md-4 myPage">
          <div class="myWrapper">
            <div class="row" style="margin-bottom: 25px; opacity: .9;">
              <div class="col-md-12">
                <h6>Havale ile para yatırma</h6>
              </div>
            </div>

            <form @submit="onSubmit" >
              <b-form-row>
                <div class="col-md-12">
                  <b-form-group>
                    <b-input  type="text"  placeholder="Adınız ve Soyadınız"  required v-model="name" />
                    <p style="opacity:.7;"><small>— Adınızı ve Soyadınızı banka hesabınızda olduğu gibi yazmalısınız...</small></p>
                  </b-form-group>
                </div>
                <div class="col-md-12">
                  <b-form-group style="opacity:.7;">
                    <b-select :options="banks" v-model="bank_name" class="form-control"></b-select>
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <input class="form-control" type="text" v-model="amount" placeholder="Miktar" min="1" required />
                    <div class="input-group-append" >
                      <span class="input-group-text" style="padding: 0.475rem 0.75rem"><small>TL</small></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-group mb-3">
                    <p class="text-danger">YATIRIM YAPARKEN AÇIKLAMA KISMINI BOŞ BIRAKINIZ.</p>
                    <p class="text-danger">AÇIKLAMA YAPILAN GÖNDERİMLER İADE EDİLİR</p>

                    <b-form-checkbox
                        id="checkbox-1"
                        v-model="aggred"
                        name="checkbox-1"
                    >
                      <p class="text-dark">Açıklamayı boş bırakmayı kabul ediyorum.</p>
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="col-md-12" v-if="aggred">
                      <b-form-group style="opacity:.7;">
                          <label>Yatırımı yapacağınız bankayı seçin</label>
                          <b-select :options="banksFromDeposit" v-model="deposit_bank_name" class="form-control"></b-select>
                      </b-form-group>
                </div>

              </b-form-row>
              <button class="btn btn-info btn-block" type="submit" v-if="!loading && aggred" style="color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
">
                <span>İşleme Başla! <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
              <button disabled class="btn btn-info btn-block" v-if="!loading && !aggred" style="color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
">
                <span>İşleme Başla! <i class="fa fa-long-arrow-right m-l-7" aria-hidden="true"></i></span>
              </button>
              <button class="btn btn-info btn-block" disabled v-if="loading" style="color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
">
                <span><i class="fas fa-spinner fa-spin"></i></span>
              </button>

            </form>
          </div>
          <hr>
          <div class="row justify-content-between" style="opacity:.7;">
            <div class="col-md-6"><small>{{ $store.getters.siteName }}</small></div>
            <div class="col-md-6" style="text-align:right;"><small>© PPAY HAVALE</small></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "One",
  data() {
    return {
      name: this.$store.getters.token.name,
      amount: '',
      loading: false,
      token: '',
      aggred: false,
      tc: '',
      banks: [
          {
              "value": "",
              "text": "Banka Seçiniz"
          },
          {
              "value": "Akbank T.A.S.",
              "text": "Akbank T.A.S."
          },
          {
              "value": "Qnb Finansbank A.S.",
              "text": "Qnb Finansbank A.S."
          },
          {
              "value": "Türkiye Garanti Bankasi A.S.",
              "text": "Türkiye Garanti Bankasi A.S."
          },
          {
              "value": "Kuveyt Türk Katilim Bankasi A.S.",
              "text": "Kuveyt Türk Katilim Bankasi A.S."
          },
          {
              "value": "Vakif Katilim Bankasi A.S.",
              "text": "Vakif Katilim Bankasi A.S."
          },
          {
              "value": "Ziraat Katilim Bankasi A.S.",
              "text": "Ziraat Katilim Bankasi A.S."
          },
          {
              "value": "Yapi ve Kredi Bankasi A.S.",
              "text": "Yapi ve Kredi Bankasi A.S."
          },
          {
              "value": "Fiba Bank A.S.",
              "text": "Fiba Bank A.S."
          },
        {
          "value": "ICBC Turkey Bank",
          "text": "ICBC Turkey Bank"
        },
        {
          "value": "Türkiye Halk Bankasi A.S.",
          "text": "Türkiye Halk Bankasi A.S."
        },
        {
          "value": "HSBC Bank A.S.",
          "text": "HSBC Bank A.S."
        },
        {
          "value": "Denizbank A.S.",
          "text": "Denizbank A.S."
        },
        {
          "value": "OdeaBank A.S.",
          "text": "OdeaBank A.S."
        },
        {
          "value": "Al Baraka Türk Katilim Bankasi A.S.",
          "text": "Al Baraka Türk Katilim Bankasi A.S."
        },
        {
          "value": "Türkiye Finans Katilim Bankasi A.S.",
          "text": "Türkiye Finans Katilim Bankasi A.S."
        },
        {
          "value": "Türkiye Cumhuriyeti Ziraat Bankasi A.S.",
          "text": "Türkiye Cumhuriyeti Ziraat Bankasi A.S."
        },
        {
          "value": "Türkiye Vakiflar Bankasi T.A.O.",
          "text": "Türkiye Vakiflar Bankasi T.A.O."
        },
        {
          "value": "HSBC Bank A.S.",
          "text": "HSBC Bank A.S."
        },
        {
          "value": "Türkiye Is Bankasi A.S.",
          "text": "Türkiye Is Bankasi A.S."
        },
        {
          "value": "ING BANK",
          "text": "ING BANK"
        },
        {
          "value": "Türk Ekonomi Bankasi A.S.",
          "text": "Türk Ekonomi Bankasi A.S."
        },
        {
          "value": "Aktif Yatirim Bankasi A.S.",
          "text": "Aktif Yatirim Bankasi A.S."
        },
        {
          "value": "Şekerbank T.A.Ş",
          "text": "Şekerbank T.A.Ş"
        },
          {
              "value": "Anadolu Bank",
              "text": "Anadolu Bank"
          },
          {
              "value": "EnPara",
              "text": "EnPara"
          },
      ],
      banksFromDeposit: [
            {
                "value": "",
                "text": "Hangi bankadan yatırım yapacaksınız?"
            },
          {
              "value": "Akbank T.A.S.",
              "text": "Akbank T.A.S."
          },
          {
              "value": "Qnb Finansbank A.S.",
              "text": "Qnb Finansbank A.S."
          },
          {
              "value": "Türkiye Garanti Bankasi A.S.",
              "text": "Türkiye Garanti Bankasi A.S."
          },
          {
              "value": "Kuveyt Türk Katilim Bankasi A.S.",
              "text": "Kuveyt Türk Katilim Bankasi A.S."
          },
          {
              "value": "Vakif Katilim Bankasi A.S.",
              "text": "Vakif Katilim Bankasi A.S."
          },
          {
              "value": "Ziraat Katilim Bankasi A.S.",
              "text": "Ziraat Katilim Bankasi A.S."
          },
          {
              "value": "Yapi ve Kredi Bankasi A.S.",
              "text": "Yapi ve Kredi Bankasi A.S."
          },
          {
              "value": "Fiba Bank A.S.",
              "text": "Fiba Bank A.S."
          },
          {
              "value": "ICBC Turkey Bank",
              "text": "ICBC Turkey Bank"
          },
          {
              "value": "Türkiye Halk Bankasi A.S.",
              "text": "Türkiye Halk Bankasi A.S."
          },
          {
              "value": "HSBC Bank A.S.",
              "text": "HSBC Bank A.S."
          },
          {
              "value": "Denizbank A.S.",
              "text": "Denizbank A.S."
          },
          {
              "value": "OdeaBank A.S.",
              "text": "OdeaBank A.S."
          },
          {
              "value": "Al Baraka Türk Katilim Bankasi A.S.",
              "text": "Al Baraka Türk Katilim Bankasi A.S."
          },
          {
              "value": "Türkiye Finans Katilim Bankasi A.S.",
              "text": "Türkiye Finans Katilim Bankasi A.S."
          },
          {
              "value": "Türkiye Cumhuriyeti Ziraat Bankasi A.S.",
              "text": "Türkiye Cumhuriyeti Ziraat Bankasi A.S."
          },
          {
              "value": "Türkiye Vakiflar Bankasi T.A.O.",
              "text": "Türkiye Vakiflar Bankasi T.A.O."
          },
          {
              "value": "HSBC Bank A.S.",
              "text": "HSBC Bank A.S."
          },
          {
              "value": "Türkiye Is Bankasi A.S.",
              "text": "Türkiye Is Bankasi A.S."
          },
          {
              "value": "ING BANK",
              "text": "ING BANK"
          },
          {
              "value": "Türk Ekonomi Bankasi A.S.",
              "text": "Türk Ekonomi Bankasi A.S."
          },
          {
              "value": "Aktif Yatirim Bankasi A.S.",
              "text": "Aktif Yatirim Bankasi A.S."
          },
          {
              "value": "Şekerbank T.A.Ş",
              "text": "Şekerbank T.A.Ş"
          },
          {
              "value": "Anadolu Bank",
              "text": "Anadolu Bank"
          },
          {
              "value": "EnPara",
              "text": "EnPara"
          }
        ],
      bank_name: "",
      deposit_bank_name: '',
        recaptcha: ''
    }
  },
  props: ['sid'],
  methods: {
    checkPayment() {
      apiService.checkPayment({sid: this.sid, token: this.token})
          .then((res) => {
            if(res.state){
              if(res.data.payment_detail){
                if(res.data.payment_detail.status === '0'){
                  if(res.data.payment_detail.aninda_deposit){
                    window.location = res.data.payment_detail.aninda_link
                  }
                  this.$store.dispatch('paymentDetail', res.data.payment_detail)
                  this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
                  this.$store.dispatch('getSteps', {
                    one: {
                      error: false,
                      now: false,
                      waiting: false,
                      approved: true
                    },
                    two: {
                      error: false,
                      now: false,
                      waiting: false,
                      approved: true
                    },
                    three: {
                      error: false,
                      now: true,
                      waiting: false,
                      approved: false
                    },
                  })
                  this.$socket.client.emit('payment', {token: this.token, sid: this.sid})
                }
              }
            }
          })

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
        this.recaptcha = document.getElementById('recaptcha_code').value
        apiService.getAccount({recaptcha: this.recaptcha, player_id: this.$route.query.player_id, deposit_bank_name: this.deposit_bank_name, tc: this.tc, bank_name: this.bank_name, sid: this.sid, name: this.name, amount: this.amount, api_trx: this.$route.query.trx})
      .then((res) => {
        this.$store.dispatch('bankName', this.bank_name)
        this.loading = false
        if(res.state) {
          if(res.approved){
            this.$store.dispatch('getErr', {error: false, message: '', waiting: false})
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              three: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
            })
            this.$store.dispatch('getToken', {
              sid: this.sid,
              name: this.$store.getters.token.name,
              trx: '',
              paymentDetail: {
                token: '',
                payment: false,
                status: '',
              }
            })
          }else{
            this.$store.dispatch('getToken', {
              sid: this.$store.getters.token.sid,
              name: this.name,
              trx: this.$store.getters.token.sid,
              paymentDetail: {
                token: res.token,
                payment: true,
                status: '0'
              }
            })
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: true,
                approved: false
              },
              three: {
                error: false,
                now: false,
                waiting: true,
                approved: false
              },
            })
            this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
            this.token = res.token
            this.checkPayment()
          }
        }
        else
          if(res.payment){
            this.$store.dispatch('getToken', {
              sid: this.$store.getters.token.sid,
              name: this.name,
              trx: this.$store.getters.token.sid,
              paymentDetail: {
                token: res.paymentData.token,
                payment: true,
                status: '0',
                bank_name: res.paymentData.bank_name
              }
            })
            this.$store.dispatch('getSteps', {
              one: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              two: {
                error: false,
                now: false,
                waiting: false,
                approved: true
              },
              three: {
                error: false,
                now: true,
                waiting: false,
                approved: false
              },
            })
            this.$store.dispatch('getErr', {error: false, message: '', waiting: true})
            this.token = res.paymentData.token
            this.checkPayment()

          }else{
            this.$store.dispatch('getConfirmMessage', {show: true, icon: 'error', title: 'Ops!', message: res.message})
          }
      })
    },
    getBanks(){
      this.loading = true
      apiService.banks()
      .then((res) => {
        this.loading = false
        this.banks = res.data
        this.banks.push({value: 'FAST', text: 'FAST Tüm Bankalar (7/24) - 100.000TL MAKS'})
        this.banks.push({value: null, text: 'Banka Seçiniz..'})
        if(!this.bank_name.length > 0){
          this.bank_name = null
        }
      })
    }
  },
  created() {
    let middle_name = this.$route.query.middle_name
    if(middle_name.length > 0)
        middle_name = ' '+middle_name+' '
    this.name = this.$route.query.name+middle_name+' '+this.$route.query.surname
    this.getBanks()
  }
}
</script>

<style scoped>
.btn-block {
  display: block;
  width: 100%;
}
.form-group {
  margin-bottom: 1rem;
}


</style>